<template>
    <div class="editor-page">
        <div class="container page">
            <div class="row">
                <div class="col-md-10 offset-md-1 col-xs-12">
                    <ej-validation-errors
                        v-if="errors"
                        :validation-errors="errors"
                    />

                    <form @submit.prevent.stop="onSubmit">
                        <fieldset>
                            <fieldset class="form-group">
                                <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    placeholder="Article Title"
                                    v-model="title"
                                />
                            </fieldset>

                            <fieldset class="form-group">
                                <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    placeholder="Description"
                                    v-model="description"
                                />
                            </fieldset>

                            <fieldset class="form-group">
                                <textarea
                                    class="form-control form-control-lg"
                                    placeholder="What is this article about?"
                                    v-model="body"
                                ></textarea>
                            </fieldset>

                            <fieldset class="form-group">
                                <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    placeholder="Enter tags"
                                    v-model="tagList"
                                />
                            </fieldset>
                            <fieldset class="form-group">
                                <button
                                    class="btn btn-large pull-xs-right btn-primary"
                                    disable="isSubmiting"
                                >
                                    Publish Article
                                </button>
                            </fieldset>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EjValidationErrors from '@/components/ValidationErrors'

export default {
    name: 'EjArticleForm',
    props: {
        initialValues: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            required: false,
        },
        isSubmiting: {
            type: Boolean,
            required: true,
        },
    },
    components: {
        EjValidationErrors,
    },
    data() {
        return {
            title: this.initialValues.title,
            description: this.initialValues.description,
            body: this.initialValues.body,
            tagList: this.initialValues.tagList.join(''),
        }
    },
    methods: {
        onSubmit() {
            const form = {
                title: this.title,
                description: this.description,
                body: this.body,
                tagList: this.tagList.split(' '),
            }
            this.$emit('articleSubmit', form)
        },
    },
}
</script>
