<template>
    <div class="banner">
        <div class="container">
            <h1>Medium Clone</h1>
            <p>A place to share knowledge</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EjBanner',
}
</script>
