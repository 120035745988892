<template>
    <div class="settings-page" v-if="currentUser">
        <div class="container page">
            <div class="row">
                <div class="col-md-6 offset-md-3 col-xs-12">
                    <h1 class="text-xs-center">Settings</h1>
                    <ej-validation-errors
                        v-if="validationsErrors"
                        :validations-errors="validationErrors"
                    />
                    <form @submit.prevent="onSubmit">
                        <fieldset>
                            <fieldset class="form-group">
                                <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    v-model="form.image"
                                    placeholder="URL of profile picture"
                                />
                            </fieldset>

                            <fieldset class="form-group">
                                <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    v-model="form.username"
                                    placeholder="Username"
                                />
                            </fieldset>

                            <fieldset class="form-group">
                                <textarea
                                    class="form-control form-control-lg"
                                    v-model="form.bio"
                                    placeholder="Short bio about you"
                                ></textarea>
                            </fieldset>

                            <fieldset class="form-group">
                                <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    v-model="form.email"
                                    placeholder="Email"
                                />
                            </fieldset>

                            <fieldset class="form-group">
                                <input
                                    type="text"
                                    class="form-control form-control-lg"
                                    v-model="form.password"
                                    placeholder="Password"
                                />
                            </fieldset>
                            <button
                                class="btn btn-lg btn-primary pull-xs-right"
                                type="submit"
                                :disabled="isSubmiting"
                            >
                                Update Settings
                            </button>
                        </fieldset>
                    </form>
                    <hr />
                    <button class="btn btn-outline-danger" @click="logout">
                        Or click here to logout.
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EjValidationErrors from '@/components/ValidationErrors'
import {mapState, mapGetters} from 'vuex'
import {
    getterTypes as authGetterTypes,
    actionTypes as authActionTypes,
} from '@/store/modules/auth'

export default {
    name: 'EjSettings',
    components: {
        EjValidationErrors,
    },
    computed: {
        ...mapState({
            isSubmiting: state => state.settings.isSubmiting,
            validationsErrors: state => state.settings.validationsErrors,
        }),
        ...mapGetters({
            currentUser: authGetterTypes.currentUser,
        }),
        form() {
            return {
                username: this.currentUser.username,
                bio: this.currentUser.bio,
                image: this.currentUser.image,
                email: this.currentUser.email,
                password: '',
            }
        },
    },
    methods: {
        onSubmit() {
            console.log('submiting new settings', this.form)
            this.$store.dispatch(authActionTypes.updateCurrentUser, {
                currentUserInput: this.form,
            })
        },
        logout() {
            console.log('logout')
            this.$store.dispatch(authActionTypes.logout).then(() => {
                this.$router.push({name: 'globalFeed'})
            })
        },
    },
}
</script>
